
import Vue from 'vue'
import { CsSimpleDialog, CsBtn, CsFlex, CsWarningDialog } from '@consteel/csuetify'
import {
  fetchProjects,
  createOnStockCollaborator,
  deleteOnStockCollaborator,
} from '@/modules/onstock'
import InviteCollaborator from '../CreateProject/InviteCollaborator.vue'
import { OnStockProject, OnStockUser } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'ManageCollaboratorsDialog',
  components: { CsWarningDialog, CsSimpleDialog, CsBtn, CsFlex, InviteCollaborator },
  data() {
    return {
      loading: false,
      deleteConfirmationDialog: false,
      deletingCollaborators: [] as OnStockUser[],
      managedCollaborators: [] as OnStockUser[],
    }
  },
  mounted() {
    this.managedCollaborators = this.collaborators
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number || String,
    },
    currentProject: {
      type: Object as () => OnStockProject,
    },
    collaborators: {
      type: Array as () => OnStockUser[],
      default: () => [],
    },
  },
  watch: {
    collaborators(value: OnStockUser[]) {
      this.managedCollaborators = value
    },
    value(value: boolean) {
      if (!value) {
        this.deletingCollaborators = []
        ;(
          this.$refs.inviteCollaborator as InstanceType<typeof InviteCollaborator>
        )?.clearNonAddedCollaborators()
      }
    },
  },
  computed: {
    deletedCollaborators() {
      return this.deletingCollaborators.map((i) => i.name || i.email).join(', ')
    },
  },
  methods: {
    async submit() {
      if (!this.projectId) {
        console.error('projectId is not given')
        return
      } //TODO handle error with snackbar

      const deletingCollaborators = this.collaborators.filter(
        (collaborator) =>
          !this.managedCollaborators.find(
            (managedCollaborator) => managedCollaborator.id == collaborator.id
          )
      )

      if (deletingCollaborators.length > 0) {
        this.deletingCollaborators = deletingCollaborators
        this.deleteConfirmationDialog = true
        return
      } else {
        await this.manageCollaborators()
      }
    },
    async manageCollaborators() {
      this.deleteConfirmationDialog = false
      this.loading = true
      const newCollaborators = this.managedCollaborators.filter(
        (managedCollaborator) =>
          !this.collaborators.find((collaborator) => collaborator.id == managedCollaborator.id)
      )

      await Promise.all(
        newCollaborators.map(async (user) => {
          try {
            await createOnStockCollaborator(this.projectId.toString(), user.id)
          } catch (error: any) {
            console.warn({ error })
          }
        })
      )

      await Promise.all(
        this.deletingCollaborators.map(async (user) => {
          try {
            await deleteOnStockCollaborator(this.projectId.toString(), user.id.toString())
          } catch (error: any) {
            console.warn({ error })
          }
        })
      )

      await fetchProjects()
      this.loading = false
      this.deletingCollaborators = []
      this.close()
    },
    close() {
      this.$emit('input', false)
    },
    resetDeletedCollaboratorsOnCancel() {
      this.managedCollaborators = this.managedCollaborators.concat(this.deletingCollaborators)
    },
  },
})
