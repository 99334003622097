
import { CsBtn } from '@consteel/csuetify'
import Vue from 'vue'
import {
  onStockStore,
  fetchOnStockUsers,
  OnStockProject,
  OnStockUser,
  OnStockPermission,
  getOnstockLoading,
} from '@/modules/onstock'
import { authStore } from '@/modules/auth'

export default Vue.extend({
  name: 'AddCollaboratorDialog',
  components: { CsBtn },
  props: {
    value: {
      type: Array as () => OnStockUser[],
      default: [],
    },
    currentProject: {
      type: Object as () => OnStockProject | null | undefined,
    },
  },
  data() {
    return {
      valid: false,
      addingCollaboratorIds: [] as string[],
    }
  },
  async mounted() {
    await fetchOnStockUsers()
  },
  computed: {
    loading(): boolean {
      return getOnstockLoading()
    },
    filteredUsers(): OnStockUser[] {
      return onStockStore.onStockUsers.filter(
        (user) =>
          !this.value.find((collaborator) => collaborator.id === user.id) &&
          user.id !== this.currentUser?.id &&
          user.id !== this.currentProject?.ownerId
      )
    },
    currentUser(): OnStockUser | null {
      return authStore.currentOnStockUser
    },
    collaboratorRules() {
      return [
        (value) => {
          if (value.length) return true

          return this.$t('At least one user is required.')
        },
      ]
    },
  },
  methods: {
    filteredRole(roles: OnStockPermission[]): OnStockPermission {
      return roles.filter((i) => i !== 'Admin')[0]
    },
    collaboratorFilter(item, queryText) {
      const textOne = item.name?.toLowerCase()
      const textTwo = item.email.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne?.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    },
    handleClickCollaboratorChipClose(id: string) {
      this.addingCollaboratorIds = this.addingCollaboratorIds.filter((i) => i !== id)
    },
    handleClickRemoveCollaborator(collaboratorToRemove: OnStockUser) {
      const collaborators = this.value.filter((coll) => coll.email !== collaboratorToRemove.email)
      this.$emit('input', collaborators)
    },
    handleAddButtonClick() {
      const isValid = (this.$refs.inviteCollaboratorForm as HTMLFormElement)?.validate()

      if (!isValid) return

      if (this.addingCollaboratorIds.length) {
        const addingCollaborators = this.addingCollaboratorIds.map((id) =>
          onStockStore.onStockUsers.find((item) => item.id === id)
        )
        ;(this.$refs.inviteCollaboratorForm as HTMLFormElement)?.resetValidation()
        const collaborators = [...this.value, ...addingCollaborators]
        this.$emit('input', collaborators)
        this.addingCollaboratorIds = []
      }
    },
    clearNonAddedCollaborators() {
      this.addingCollaboratorIds = []
    },
  },
})
