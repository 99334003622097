var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cs-compare-dialog" },
    [
      _c(
        "v-form",
        {
          ref: "inviteCollaboratorForm",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "align-center" },
            [
              _c("v-col", [
                _vm._v(" " + _vm._s(_vm.$t("Collaborators")) + " "),
              ]),
              _c(
                "v-col",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      loading: _vm.loading,
                      variant: "underlined",
                      rules: _vm.collaboratorRules,
                      items: _vm.filteredUsers,
                      "item-value": "id",
                      filter: _vm.collaboratorFilter,
                      chips: "",
                      multiple: "",
                      dense: "",
                      required: "",
                      "no-data-text": _vm.$tc("No available user"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "d-flex flex-grow-1" }, [
                              _c("div", { staticClass: "flex-grow-1" }, [
                                _vm._v(
                                  _vm._s(item.name) +
                                    " (" +
                                    _vm._s(item.email) +
                                    ")"
                                ),
                              ]),
                              _c("div", { staticClass: "pl-4" }, [
                                _vm._v(_vm._s(_vm.filteredRole(item.roles))),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "selection",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { small: "", close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.handleClickCollaboratorChipClose(
                                      item.id
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name || item.email))]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.addingCollaboratorIds,
                      callback: function ($$v) {
                        _vm.addingCollaboratorIds = $$v
                      },
                      expression: "addingCollaboratorIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", disabled: !!_vm.valid },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c(
                                    "cs-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        block: "",
                                        disabled: !_vm.valid,
                                      },
                                      on: { click: _vm.handleAddButtonClick },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Add")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("No collaborator selected.")) + " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "collaborator-list" },
            _vm._l(_vm.value, function (collaborator, i) {
              return _c(
                "div",
                { key: i, staticClass: "d-flex align-center text-body-2" },
                [
                  _c("div", { staticClass: "flex-grow-1 ma-1" }, [
                    _vm._v(_vm._s(collaborator.name || collaborator.email)),
                  ]),
                  _c("div", { staticClass: "ma-1 mx-2" }, [
                    _vm._v(
                      " " + _vm._s(_vm.filteredRole(collaborator.roles)) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ma-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClickRemoveCollaborator(
                                collaborator
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                            _vm._v(" mdi-close "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }