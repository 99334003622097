var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        title: _vm.$t("Share settings"),
        value: _vm.value,
        maxWidth: 550,
        persistent: "",
      },
      on: { input: _vm.close },
    },
    [
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          submitText: "Megszüntetés",
          width: "380",
          header: "",
          title: _vm.$t("Stop sharing"),
          description:
            _vm.$t(
              "Are you sure you want to stop sharing the model with the following users: "
            ) +
            `${_vm.deletedCollaborators}?` +
            _vm.$t("These users will not be able to access this model."),
        },
        on: {
          submit: _vm.manageCollaborators,
          cancel: _vm.resetDeletedCollaboratorsOnCancel,
        },
        model: {
          value: _vm.deleteConfirmationDialog,
          callback: function ($$v) {
            _vm.deleteConfirmationDialog = $$v
          },
          expression: "deleteConfirmationDialog",
        },
      }),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", absolute: "", top: "" },
          })
        : _vm._e(),
      _c(
        "invite-collaborator",
        {
          ref: "inviteCollaborator",
          staticClass: "my-6",
          attrs: { currentProject: _vm.currentProject },
          model: {
            value: _vm.managedCollaborators,
            callback: function ($$v) {
              _vm.managedCollaborators = $$v
            },
            expression: "managedCollaborators",
          },
        },
        [_vm._v(_vm._s(_vm.$t("Back")))]
      ),
      _c(
        "cs-flex",
        { attrs: { justify: "space-between" } },
        [
          _c(
            "cs-btn",
            {
              staticClass: "cancel",
              attrs: { outlined: "", disabled: _vm.loading },
              on: { click: _vm.close },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "cs-btn",
            { attrs: { loading: _vm.loading }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }